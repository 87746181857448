import React from 'react'

function InputField({ label, id, value = '', setValue = () => { }, placeholder, className }) {
    const onChange = (e) => {
        setValue(e.target.value);
    }

    return (
        <div className='flex flex-col items-start w-full'>
            <label htmlFor={id} className="text-[#A6A6A6] text-lg">{label}</label>
            <input type="text"
                id={id}
                name={id}
                className={`rounded-xl p-1 md:p-2 bg-[#EDF1F4] border-2 border-[#CBD5E0] w-full ${className} text-black`}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
            />

        </div>
    )
}

export default InputField
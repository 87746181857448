import React from 'react'
import logo from "../images/Texam-logo-white.png"
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMailSharp } from "react-icons/io5";
import { IoLocationSharp } from "react-icons/io5";
import WavePattern from "../images/wave.svg"
import { useSharedCalendly } from '../hooks/useCalendly';
import { Newsletter } from './Newsletter';


function Footer() {
    const { openInterviewModal } = useSharedCalendly();
    return (
        <div className='bg-[#4642ee] text-white relative pattern  overflow-hidden' id='footer'>
            {/* <div className="curve"></div> */}
            <div className='bg-wave-pattern h-[260px]'></div>
            <div className="container mx-auto     mt-[-150px]    ">

                <div className='flex flex-col px-3 py-5 space-y-8'>
                    <div className='flex flex-col space-y-5 lg:flex-row lg:justify-between gap-3 w-full '>
                        <div className=' flex flex-col  space-y-5'>
                            <img src={logo} alt="" width="200px" height="100px" />
                            <h1 className='font-robo font-bold  md:text-lg text-left max-w-[400px]'>Linking Exam to Technology, Creating AI based Online Proctoring Platform.</h1>
                            <Newsletter />


                        </div>
                        <div className='col-span-3 flex flex-col space-y-3 md:flex-row md:space-x-10 md:space-y-0 md:justify-between   w-full'>
                            <div className='text-left  flex flex-col space-y-5'>

                                <h4 className='text-xl font-semibold'>Company</h4>
                                <ul className=' flex flex-col space-y-3'>
                                    <a href="#">
                                        <li>Home</li>
                                    </a>
                                    <li>FAQ</li>
                                    <li>Blog</li>
                                    <li>About Us</li>
                                    <a href="#footer" onClick={openInterviewModal} >
                                        <li>Careers</li>
                                    </a>
                                </ul>
                            </div>
                            <div className='text-left flex  flex-col space-y-5'>

                                <h4 className='text-xl font-semibold'>Product</h4>
                                <ul className=' flex flex-col space-y-3'>
                                    <li>Pricing</li>
                                    <li>Customers</li>
                                    <li>Testimonials</li>
                                </ul>
                            </div>
                            <div className='text-left flex flex-col items-start space-y-5'>

                                <h4 className='text-xl font-semibold'>Contact</h4>
                                <ul className=' flex flex-col space-y-3 max-w-[300px]'>
                                    <li className='flex items-center space-x-2'><FaPhoneAlt /><span>(+91) 9772332434</span></li>
                                    <li className='flex items-center space-x-2'><IoMailSharp /><span>info@texam.com</span></li>
                                    <a href="https://www.google.com/search?q=euro+technologies+udaipur&oq=euro+technologies+udaipur&aqs=edge..69i57j0i546l4j69i61j69i64.14212j0j1&sourceid=chrome&ie=UTF-8"><li className='flex items-center space-x-2'><span className='text-lg'><IoLocationSharp /></span><span> H-1-30, IT Park RIICO Ind. Area, Madri, Rajasthan 313001</span></li></a>

                                </ul>
                                <div className='flex justify-start  md:text-3xl space-x-4 md:space-x-8'>
                                    {/* <i>
                                        <FaFacebookF />
                                    </i>
                                    <i>

                                        <FaTwitter />
                                    </i> */}

                                    <a href="https://www.linkedin.com/company/texam-01/">

                                        <i>

                                            <FaLinkedinIn />
                                        </i>
                                    </a>
                                    {/* 
                                    <i>

                                        <FaInstagram />
                                    </i> */}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-around py-3  border-t-2 space-x-2 '>
                        <a href={process.env.PUBLIC_URL + '/tnc.txt'} className='underline'>Privacy Policy</a>
                        <a href={process.env.PUBLIC_URL + '/tnc.txt'} className='underline'>Cookie Policy</a>
                        <a href={process.env.PUBLIC_URL + '/tnc.txt'} className='underline'>Terms of Service</a>
                    </div>
                    <div>
                        CopyRight &copy;  TEXAM 2022. All Rights Reserved.
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Footer
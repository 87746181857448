import axios from "axios";

const SLACK_WEBHOOK_URL = ['https://hooks.slack.com/services/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', 'https://hooks.slack.com/services/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']
const DISCORD_WEBHOOK_URL = ['https://discord.com/api/webhooks/1008336400262893579/l81lqdaC1ZZ8uxtpt3RqNpOMTaABgb_9V1cC90YUyTLjhnaEMMCvAh7ya7BGMwwPlyMa']

export const FORMTYPES = { CONTACT: 'CONTACT', NEWSLETTER: 'NEWSLETTER' }

export const submitForm = async (formType, { firstName, lastName, message, email }, e) => {
    if (e) e.preventDefault();
    if (!(formType in FORMTYPES)) return false;

    let slackPayload = {}
    let discordPayload = {}

    if (formType === FORMTYPES.CONTACT) {
        slackPayload = {
            "text": `TEXAM Contact Form \nName: ${firstName} ${lastName} \nEmail: ${email} \nMessage: ${message} \n`.trim()
        };
        discordPayload = {
            embeds: [{
                title: 'Contact Form Submitted',
                fields: [
                    { name: 'Name', value: `${firstName} ${lastName}` },
                    { name: 'Email', value: email },
                    { name: 'Message', value: message }
                ]
            }],
        };

    }
    else if (formType === FORMTYPES.NEWSLETTER) {
        slackPayload = {
            "text": `TEXAM NEWSLETTER Subscriber: ${email} \n`
        };
        discordPayload = {
            embeds: [{
                title: 'Newsletter Subscriber',
                fields: [
                    { name: 'Email', value: email },
                ]
            }],
        };

    } else return false;

    let promises = []

    for (let url of SLACK_WEBHOOK_URL) promises.push(sendOnSlack(url, slackPayload));
    for (let url of DISCORD_WEBHOOK_URL) promises.push(sendOnDiscord(url, discordPayload));
    const responses = await Promise.all(promises)

    for (let res of responses) if (res.status === 200) return true;
    return false;

    // if (res.status === 200) {
    //     alert("Message Sent!")

    //     //clear state so text boxes clear
    //     setName('');
    //     setEmail('');
    //     setMessage('');
    // } else {
    //     alert("There was an error.  Please try again later.")
    // }
}

const sendOnSlack = (url, data) => axios.post(url, JSON.stringify(data), {
    withCredentials: false,
    transformRequest: [(data, headers) => {
        delete headers.post["Content-Type"]
        return data
    }]
})

const sendOnDiscord = (url, data) => fetch(url, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
});


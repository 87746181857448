import React, { useState } from 'react'
import { FORMTYPES, submitForm } from '../utils/form';
import Button from './Button'
import InputField from './InputField'
import validator from 'validator'
function ContactForm() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const onFormSubmit = (e) => {
        e.preventDefault();
    }

    const handleSubmit = async () => {
        if (validator.isEmail(email)) {
            const sub = await submitForm(FORMTYPES.CONTACT, {
                firstName, lastName, email, message
            })
            if (!sub) return alert("There was an error.  Please try again later.");

        }
        else {
            return alert("E-mail is not valid!");
        }


        alert('Submitted Successfully');
        setFirstName('');
        setLastName('');
        setEmail('');
        setMessage('');
    }
    return (
        <div id='contact-form' className=' flex flex-col items-center py-5 px-3 space-y-5 shadow-2xl border  max-w-[650px] mx-auto my-10 rounded-xl'>
            <h1 className='text-2xl font-semibold  p-2 rounded-lg'>CONTACT US</h1>
            <form className='max-w-[500px] flex flex-col space-y-3' onSubmit={onFormSubmit}>
                <div className='grid grid-cols-2 gap-2'>

                    <InputField label="First Name" id="firstName" value={firstName} setValue={setFirstName} />
                    <InputField label="Last Name" id="lastName" value={lastName} setValue={setLastName} />
                </div>
                <div className=''>
                    <InputField label="E-mail" id="email" value={email} setValue={setEmail} />
                </div>
                <div className='flex flex-col items-start w-full'>
                    <label htmlFor="message" className="text-[#A6A6A6] text-lg">Message</label>
                    <textarea
                        name="message"
                        id="message"
                        cols="30"
                        rows="4"
                        className='rounded-xl p-2 bg-[#EDF1F4] text-black border-2 border-[#CBD5E0] w-full'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </div>
            </form>
            <Button text="Submit" onClick={handleSubmit} className="bg-CSec text-white border-2 border-black hover:scale-110"></Button>
        </div>
    )
}

export default ContactForm


//className="bg-CSec text-white hover:scale-110
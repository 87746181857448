import React from "react";
import { useSharedCalendly } from "../hooks/useCalendly";
import Logo from "../images/Texam-logo-white.png";
import Button from "./Button";

function Header() {
  const { openDemoModal, openInterviewModal } = useSharedCalendly();

  const handleCareersButton = () => {
    // openInterviewModal()
    window.open("https://texam.io/apply", "_blank");
  };

  return (
    <header className="w-full">
      <div className="container mx-auto px-3">
        <div className="flex justify-between items-center py-4">
          <div>
            <img src={Logo} alt="" width="150px" height="114px" />
          </div>
          <div className=" hidden md:flex  list-none">
            <a href="#" className="mx-2 ">
              <li className="hover:font-bold">Home</li>
            </a>
            {/* <a href="#" className='mx-2'>
                            <li className='hover:font-bold'>FAQ</li>
                        </a>
                        <a href="#" className='mx-2'>
                            <li className='hover:font-bold'>Blog</li>
                        </a> */}
            <a href="#footer" className="mx-2">
              <li className="hover:font-bold">About Us</li>
            </a>
            <a href="#contact-form" className="mx-2">
              <li className="hover:font-bold">Contact Us</li>
            </a>
            <a onClick={handleCareersButton} className="mx-2">
              <li className="hover:font-bold"> Careers </li>
            </a>
          </div>
          <div className="flex items-center">
            {/* <a href="#" className='mx-3 hover:font-bold'>Login</a> */}
            <a className="mx-1">
              <Button
                onClick={openDemoModal}
                text="Get Demo"
                className="bg-CSec hover:scale-110"
              ></Button>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;

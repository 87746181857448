import Pig from "../images/pig.png"
import OrderRide from "../images/Order_Ride.svg"
import Mobileuser from "../images/Mobileuser.svg"
import Focus from "../images/Focus.svg"


export const FirstGrid = () => {
    return (
        <>
            <div className="grid md:grid-cols-2 gap-5 md:gap-10 grid-row-10 max-w-[1000px] mx-auto text-black min-h-[80vh] w-[80%] px-3 py-20">
                <div className="w-full h-full md:mb-0 mb-8  bg-[#D8D8D8] rounded-xl flex flex-col md:flex-row  md:justify-center justify-between p-4 md:p-12 overflow-hidden  row-span-4">
                    <h1 className="text-[1.5rem] md:text-4xl font-bold text-[#179B6B] text-left">User <br />Friendly</h1>

                    <img className="w-[285px]h-[285px] md:w-[285px] md:h-[322px] text-center md:pl-0 pl-8" src={Mobileuser} alt="" />

                </div>
                <div className="w-full h-full bg-pink-gradient text-white rounded-xl flex flex-col items-center p-4 md:p-9  row-span-6 overflow-hidden">
                    <h1 className="text-[1.5rem] md:text-4xl font-bold text-left w-full">Cost <br /> Effective</h1>
                    <img src={Pig} alt="" />
                </div>
                <div className="w-full h-full bg-[#D8D8D8] rounded-xl flex flex-col items-center justify-between p-4 md:p-9  row-span-6 gap-5 overflow-hidden">
                    <h1 className="text-[1.5rem] md:text-4xl font-bold text-left bg-red-grey bg-clip-text text-fill-transparent w-full">Fully <br /> Customisable</h1>
                    <div className="flex flex-col justify-center h-full ">

                        <img src={Focus} alt="" />
                    </div>

                </div>
                <div className="w-full h-full bg-[#FDFFEA] rounded-xl flex flex-col md:flex-row gap-5   justify-between p-4 md:p-9  row-span-4 overflow-hidden">
                    <h1 className="text-[1.5rem] md:text-4xl font-bold text-left bg-red-orange bg-clip-text text-fill-transparent">Respects <br /> Privacy</h1>
                    <img src={OrderRide} alt="" />
                </div>
            </div>
        </>
    )
}
import { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "./../images/webcam_pic.svg";
import { handDetectionAlgo, countFingers, stopHandAlgo, } from "./scripts/hand";
import { faceDetectionAlgo, countFaces, getFaceDistance, stopFaceAlgo, } from './scripts/face';
import { useCardRatio } from './useCardRatio';
import { Measure } from 'react-measure';
// const WIDTH = '991px';
// const HEIGHT = '558px';

// const videoWidth = 991;
// const videoHeight = 558;
let w = window.innerWidth;
const videoWidth = w <= 380 ? 350 : (w <= 420 ? 390 : ((w < 1024) ? 750 : 991));
const videoHeight = w <= 380 ? 200 : (w <= 420 ? 300 : ((w < 1024) ? 500 : 558));

const WIDTH = `${videoWidth}px`
const HEIGHT = `${videoHeight}px`

const canvasStyles = {
    // position: 'absolute',
    // position: 'relative',
    // margin: '10px',
    // bottom: '0',
    // left: '0',
}

export const AiProctorDemo = () => {

    const [isStarted, setIsStarted] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);

    const [faces, setFaces] = useState();
    const [fingers, setFingers] = useState();
    const [distance, setDistance] = useState();

    const videoElement = useRef(null);
    const videoSelect = useRef(null);

    const canvasCtx0 = useRef(null);
    const canvasCtx1 = useRef(null);
    const canvasCtx2 = useRef(null);

    const handleStreamChange = async () => {
        if (window.stream) {
            window.stream.getTracks().forEach(track => {
                track.stop();
            });
        }
        const videoSource = videoSelect.current.value;
        const stream = await setupCamera(videoWidth, videoHeight, videoSource)

        videoSelect.current.selectedIndex = [...videoSelect.current.options].
            findIndex(option => option.text === stream.getVideoTracks()[0].label);

        videoElement.current.srcObject = stream
        window.stream = stream
    }

    const setupCamera = async (width, height, videoSource) => {
        const stream = await navigator.mediaDevices.getUserMedia({
            video: {
                width: width || 303, height: height || 220,
                deviceId: videoSource ? { exact: videoSource } : undefined
            },
            audio: false,
        })
        return stream
    };

    const onHandAlgoResults = useCallback((results) => {

        // canvasCtx0.current.save();
        // canvasCtx0.current.clearRect(0, 0, canvasCtx0.current.width, canvasCtx0.current.height);

        // canvasCtx.drawImage(
        //     results.image,
        //     0,
        //     0,
        //     canvasElement.width,
        //     canvasElement.height
        // );


        if (results.multiHandLandmarks) {
            const fingers = countFingers(results)
            setFingers(fingers || 'NA')
            console.log(fingers)

            // canvasCtx2.current.clearRect(0, 0, canvasCtx0.current.width, canvasCtx0.current.height);
            // canvasCtx2.current.font = "15px Arial";
            // canvasCtx2.current.strokeText(`Fingers ${fingers || 'N/A'}`, 10, 70);

            // for (const landmarks of results.multiHandLandmarks) {
            //     // const fingers = countFingers(results)
            //     // canvasCtx2.clearRect(0, 0, canvasElement[0].width, canvasElement[0].height);
            //     // canvasCtx2.font = "15px Arial";
            //     // canvasCtx2.strokeText(`Fingers ${fingers}`, 10, 70);
            //     // console.log(fingers)
            //     drawConnectors(canvasCtx0, landmarks, HAND_CONNECTIONS, {
            //         color: "#00FF00",
            //         lineWidth: 5,
            //     });
            //     drawLandmarks(canvasCtx0, landmarks, {
            //         color: "#FF0000",
            //         lineWidth: 2,
            //     });
            // }
        } else {
            setFingers()
        }
        // canvasCtx0.current.restore();

    }, [])

    const onDetectFacesPredictions = useCallback(
        async (prediction) => {
            // canvasCtx1.current.save();
            // canvasCtx1.current.clearRect(0, 0, canvasCtx1.current.width, canvasCtx1.current.height);


            // canvasCtx1.drawImage(videoElement, 0, 0, canvasElement[1].width,
            //     canvasElement[1].height);

            if (prediction.length) {


                const faces = countFaces(prediction)
                const distance = getFaceDistance(prediction)
                setFaces(faces || 'NA');
                setDistance(distance ? Math.round(((distance + Number.EPSILON) * 100) / 100) : 'NA');
                console.log(faces, distance);

                // canvasCtx1.current.font = "15px Arial";
                // canvasCtx1.current.strokeText(`Faces ${faces}`, 10, 50);

                // prediction.forEach((pred) => {
                //     canvasCtx1.beginPath();
                //     canvasCtx1.lineWidth = "4";
                //     canvasCtx1.strokeStyle = "blue";
                //     canvasCtx1.rect(
                //         pred.topLeft[0],
                //         pred.topLeft[1],
                //         pred.bottomRight[0] - pred.topLeft[0],
                //         pred.bottomRight[1] - pred.topLeft[1]
                //     );
                //     canvasCtx1.stroke();
                //     canvasCtx1.fillStyle = "red";
                //     pred.landmarks.forEach((landmark) => {
                //         canvasCtx1.fillRect(landmark[0], landmark[1], 5, 5)
                //     });
                // });
            } else {
                setFaces(faces);
                setDistance(distance);
            }
            // canvasCtx1.current.restore();

        }, []);


    const handleOnClick = async () => {
        if (isStarted) {
            stopFaceAlgo();
            stopHandAlgo();
            setIsStarted(false);
            return;
        };

        if (!isInitialized) {
            canvasCtx0.current = canvasCtx0.current.getContext('2d');
            canvasCtx1.current = canvasCtx1.current.getContext('2d');
            canvasCtx2.current = canvasCtx2.current.getContext('2d');

            videoSelect.current.onchange = handleStreamChange;
            const devices = await navigator.mediaDevices.enumerateDevices();
            for (const deviceInfo of devices) {
                const option = document.createElement('option');
                option.value = deviceInfo.deviceId;
                if (deviceInfo.kind === 'videoinput') {
                    option.text = deviceInfo.label || `Camera ${videoSelect.current.length + 1}`;
                    videoSelect.current.appendChild(option);
                }
            }

            videoElement.current.style.cssText = `-moz-transform: scale(-1, 1); \
                -webkit-transform: scale(-1, 1); -o-transform: scale(-1, 1); \
                transform: scale(-1, 1); filter: FlipH;`;

            videoElement.current.srcObject = await setupCamera(videoWidth, videoHeight)

            handDetectionAlgo({
                videoElement: videoElement.current,
                resultHandler: onHandAlgoResults,
                errorHandler: console.error
            });

            faceDetectionAlgo({
                videoElement: videoElement.current,
                resultHandler: onDetectFacesPredictions,
                errorHandler: console.error
            });
            setIsInitialized(true);
            setIsStarted(true);
            return;
        }

        // setIsStarted(true);
    }

    return <>
        <div className="mt-20 flex flex-col items-center gap-2 w-full">
            <h1 className="text-3xl"> Click here to watch <span className="text-CSec ">AI</span> in action</h1>
            <div className='relative' style={{ height: HEIGHT, width: WIDTH }} onClick={handleOnClick}>
                <video
                    id="layer0"
                    ref={videoElement}
                    className="input_video z-0 absolute left-0 right-0"
                    autoPlay
                    width={WIDTH}
                    height={HEIGHT}
                    style={canvasStyles}
                    onLoadStart={() => {
                        console.log('onLoadStart', new Date());
                    }}
                    onLoad={() => {
                        console.log('onLoad', new Date());
                    }}

                />
                <canvas
                    id="layer1"
                    ref={canvasCtx0}
                    className="output_canvas z-10 absolute left-0 right-0"
                    width={WIDTH}
                    height={HEIGHT}
                    style={canvasStyles}
                />
                <canvas
                    id="layer2"
                    ref={canvasCtx1}
                    className="output_canvas z-20 absolute left-0 right-0"
                    width={WIDTH}
                    height={HEIGHT}
                    style={canvasStyles}
                />
                <canvas
                    id="layer3"
                    ref={canvasCtx2}
                    className="output_canvas z-30 absolute left-0 right-0"
                    width={WIDTH}
                    height={HEIGHT}
                    style={canvasStyles}
                />

                {!isStarted && <img
                    className='z-0 absolute  left-0 right-0 h-full'
                    alt="Demo Background"
                    id="default-img"
                    src={Webcam}
                    width={WIDTH}
                    height={HEIGHT}
                    style={canvasStyles}
                    onClick={handleOnClick}
                />}

            </div>
            <select ref={videoSelect} id="videoSource" hidden />
            {isStarted && <>
                Faces: {faces} <br />
                Fingers: {fingers} <br />
                Distance: {`${distance} cm`}
            </>}
        </div>
    </>
}

import React, { useState } from "react";
import Button from "./Button";
import Play from "../images/Play.svg";
import Feedback from "../images/Feedback.svg";
import Tick from "../images/Tick.svg";
import { useSharedCalendly } from "../hooks/useCalendly";

import ModalVideo from "react-modal-video";
import "/node_modules/react-modal-video/scss/modal-video.scss";

function Hero() {
  const { openDemoModal } = useSharedCalendly();
  const [isVideoPlayerOpen, setIsVideoPlayer] = useState(false);
  const handleGetDemoButton = () => {
    setIsVideoPlayer(true);
  };
  return (
    <>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isVideoPlayerOpen}
        videoId="Z61ykvnain0"
        onClose={() => setIsVideoPlayer(false)}
      />
      <div className="pt-10">
        <div className="container mx-auto px-3 md:px-10">
          <div className="flex flex-col space-y-4">
            <div className="grid grid-cols-5 ">
              <div className="flex flex-col space-y-2 col-span-2">
                <h1 className="font-bold lg:text-7xl md:text-4xl text-xl text-left">
                  Redefining Examinations
                </h1>
                <img src={Tick} alt="" />
                <p className="text-left text-xs md:text-lg">
                  We offer solution on the inefficiency of online testing
                  platforms with bulk load of unwanted activity during online
                  exam by our specialised AI based system
                </p>
              </div>
              <div className="col-span-3">
                <img
                  src={Feedback}
                  alt=""
                  className="max-h-[400px] mt-3 mx-auto"
                />
              </div>
            </div>
            <div className="w-full flex justify-center md:justify-start space-x-2">
              <Button
                onClick={openDemoModal}
                text="Take free trial"
                className="bg-CSec text-white hover:scale-110"
              ></Button>
              <a
                onClick={handleGetDemoButton}
                className="flex items-center space-x-1 md:p-1 hover:scale-110"
              >
                <img src={Play} alt="" />{" "}
                <span className="text-center">Get Demo</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;

export const Annoucment = ({ text, link }) => {
    return (
        <div className="flex justify-center mt-5 pt-5 ">
            <div className='px-10 md:px-40 bg-[#d9d9d9] text-black  py-2 '>
                {link ?

                    <a href={link} className="underline">
                        {text}
                    </a>
                    :
                    { text }
                }
            </div>
        </div>
    )
}


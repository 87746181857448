import { BsFacebook } from "react-icons/bs"
import { BsTwitter } from "react-icons/bs"
import { BsLinkedin } from "react-icons/bs"
import { BsInstagram } from "react-icons/bs"
export const TeamMember = ({ img, name, desc, social, role }) => {
    return (
        <div className="flex flex-col gap-3 py-3 px-2 items-center bg-[#1E1E1E] rounded-xl w-[300px] h-[430px]">
            <div className="flex flex-col">

                <img src={img} alt="" className="rounded-[50%] w-[200px] h-auto mb-5" />
                <h2 className="font-bold">{name}</h2>
            </div>
            <p className="h-[135px] mt-2 px-2">{desc}</p>
            {/* <div className="flex flex-col gap-4"> */}
                <p>{role}</p>
                <div className="flex text-xl justify-between w-[50%]">
                    <a href={social.fb}>
                        <BsFacebook />
                    </a>
                    <a href={social.Link} target="_blank">
                        <BsLinkedin />
                    </a>
                    <a href={social.tw}>
                        <BsTwitter />
                    </a>
                    {social.insta!=="#"?<a href={social.insta}><BsInstagram/></a>:<a className="hidden"></a>}
                    {/* {if(social.insta!=="#"){
                        return <a href={social.insta}><BsInstagram/></a>
                    }} */}

                </div>
            {/* </div> */}
        </div>
    )
}
import { useEffect, useState } from "react";
import { PopupModal } from "react-calendly";
import { useCalendly, useSharedCalendly } from "../hooks/useCalendly";

export const CalendlyPopupModal = ({ pageSettings, utm, prefill }) => {
    const { isModalOpen, closeModal, url } = useSharedCalendly();

    return <>
        <PopupModal
            url={url}
            pageSettings={pageSettings}
            utm={utm}
            prefill={prefill}
            onModalClose={closeModal}
            open={isModalOpen}
            rootElement={document.getElementById("root")}
        />
    </>

}
import { TeamMember } from "./TeamMember";
import MemberPng from "../images/member.png";
import HarshPng from "../images/harsh.png";
import DevashishPng from "../images/devashish.png";
import DivyanshJpg from "../images/divyansh.png";

export const TeamSection = () => {
  return (
    <div className="flex flex-col gap-5 py-10">
      <h2 className="text-4xl mb-10">
        Meet our <span className="text-CSec">Team</span>
      </h2>
      <div className="flex flex-col gap-5 md:flex-row items-center justify-evenly px-3 mb-10">
        <TeamMember
          name={"Harsh Singhvi"}
          desc="harsh@texam.in"
          role="Founder & CEO"
          img={HarshPng}
          social={{
            fb: "#",
            Link: "https://www.linkedin.com/in/harsh-singhvi",
            tw: "#",
            insta: "#",
          }}
        />
        <TeamMember
          name={"Devashish Upadhyay"}
          desc="devashish@texam.in"
          role="Co Founder & CFO"
          img={DevashishPng}
          social={{
            fb: "#",
            Link: "https://www.linkedin.com/in/harsh-singhvi",
            tw: "#",
            insta: "#",
          }}
        />
        <TeamMember
          name={"Divyansh Gupta"}
          desc="divyansh@texam.in"
          role="Co Founder & Product Designer"
          img={DivyanshJpg}
          social={{ fb: "#", insta: "#", tw: "#" }}
        />
      </div>
    </div>
  );
};

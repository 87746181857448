import React from 'react'

function Button({ className, text, onClick = () => { } }) {
    return (
        <button className={`rounded-3xl px-2 py-2 md:py-2 md:px-4 text-white ${className}`} onClick={onClick}>
            {text}
        </button >
    )
}

export default Button
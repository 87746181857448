import { useCallback, useState } from "react"
import { useBetween } from 'use-between';

// let isModalOpen;

const useCalendly = () => {
    // const forceUpdate = useForceUpdate();
    // const setIsModalOpen = useCallback((isOpen) => {
    //     isModalOpen = isOpen
    //     forceUpdate();
    // }, []);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [url, setUrl] = useState('https://calendly.com/texam/');

    const openModal = useCallback(() => setIsModalOpen(true), []);
    const closeModal = useCallback(() => {
        setUrl('https://calendly.com/texam/');
        setIsModalOpen(false)
    }, []);

    const openDemoModal = useCallback(() => {
        setUrl('https://calendly.com/harshsinghvi/15min');
        setIsModalOpen(true);
    }, []);

    const openInterviewModal = useCallback(() => {
        setUrl('https://calendly.com/texam/interview');
        setIsModalOpen(true);
    }, []);

    return {
        isModalOpen,
        url,
        openModal,
        openDemoModal,
        openInterviewModal,
        closeModal
    }
}
export const useSharedCalendly = () => useBetween(useCalendly);

// //create your forceUpdate hook
// export const useForceUpdate = () => {
//     const [value, setValue] = useState(0); // integer state
//     const forceUpdate = useCallback(() => setValue(value => value + 1), []);
//     return forceUpdate;
// }

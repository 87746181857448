import React, { useState } from 'react'
import { FORMTYPES, submitForm } from '../utils/form';
import Button from './Button'
import InputField from './InputField'

export const Newsletter = () => {
    const [email, setEmail] = useState('');

    const onFormSubmit = (e) => {
        e.preventDefault();
    }

    const handleSubmit = async () => {
        const sub = await submitForm(FORMTYPES.NEWSLETTER, { email })
        if (!sub) return alert("There was an error.  Please try again later.");
        alert('Subscribed Successfully!');
        setEmail('');
    }

    return (
        <div id='contact-form' className='flex flex-col items-start  space-y-5'>
            <h1 className='text-lg font-semibold'>Subscribe to our Newsletter</h1>
            <form className='max-w-[500px] flex flex-col space-y-3' onSubmit={onFormSubmit}>
                <div className='flex flex-col items-start md:flex-row md:items-end gap-2'>
                    <InputField placeholder="E-mail" id="email" value={email} setValue={setEmail} className="min-w-[300px]" />
                    <Button text="Subscribe" onClick={handleSubmit} className="bg-black text-white border-2 border-black"></Button>
                </div>
            </form>
        </div>
    )
}
